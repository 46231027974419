.root {
  /* While the viewbox of the icon is 30x30, the icon size is scaled to
   28x28 by default, which results in scaling the whole image and the
   strokes a bit.*/
  width: 28px;
  height: 28px;

  stroke: var(--marketplaceColor);
  stroke-width: 3px;
}

.skCubeGrid{
  width: 40px;
  height: 40px;
  margin: 500px auto;
}

.skCube {
  width: 33%;
  height: 33%;
  background-color: #2c3e50;
  float: left;
  -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
          animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out; 
}
 .skCube1 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }
 .skCube2 {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s; }
 .skCube3 {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s; }
 .skCube4 {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s; }
 .skCube5 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }
 .skCube6 {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s; }
 .skCube7 {
  -webkit-animation-delay: 0s;
          animation-delay: 0s; }
 .skCube8 {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s; }
 .skCube9 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }

@-webkit-keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1);
  } 35% {
    -webkit-transform: scale3D(0, 0, 1);
            transform: scale3D(0, 0, 1); 
  }
}

@keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1);
  } 35% {
    -webkit-transform: scale3D(0, 0, 1);
            transform: scale3D(0, 0, 1);
  } 
}