@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  /* flex-direction: column; */
  gap: 20px;
  /* Remove link's hover effect */
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
  &:hover {
    text-decoration: none;
  }
  @media (max-width: 576px) {
    flex-direction: column;
    gap: 10px;
  }
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: var(--colorGrey100); /* Loading BG color */
  border-radius: var(--borderRadiusMedium);
  width: 130px;

  @media (--viewportMedium) {
    width: 250px;
    min-width: 250px;
    &:hover {
      transform: scale(1.02);
      box-shadow: var(--boxShadowListingCard);
    }
  }
  @media (max-width: 768px) {
    width: 100%;
   }
}

.rootForImage {
  border: solid 1px var(--colorGrey100);
  border-radius: var(--borderRadiusMedium);
  width: 100%;
  height: 100%;
}

.info {
  /* Layout */
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 14px 0 2px 0;
  @media (max-width: 600px) {
    padding: 20px;
  }
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-bottom: 4px;
}

.priceValue {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  color: var(--marketplaceColor);
  display: inline-flex;
  align-items: center;
  margin-left: 2px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  /* display: flex; */
  flex-direction: column;
}

.title {
  /* Font */
  composes: textSmall from global;

  font-weight: var(--fontWeightBold);
  color: var(--colorGrey700);
  margin: 0;
  @media (--viewportMedium) {
    font-size: 24px;
    margin: 8px 0;
  }
}

.authorInfo {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey700);

  padding-top: 4px;
  padding-bottom: 4px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.requestCallBackBtn {
  border-left: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 20px;
  margin-right: 20px;
  padding-left: 20px;
  padding-top: 15px;
  @media (max-width: 767px) {
    padding-left: 14px;
  }
  @media (max-width: 600px) {
display: none;
  }
  
}

.verified{
  background-color: #a8e4a8;
  padding: 8px 16px;
  font-size: 15px;
  color: white;
  border-radius: 25px;
  @media(max-width: 600px){
    padding: 3px 15px;
    font-size: 12px;
  }
}

.notVerified{
  background-color: #f86767;
  padding: 8px 16px;
  font-size: 15px;
  color: white;
  border-radius: 25px;
  @media(max-width: 600px){
    padding: 3px 15px;
    font-size: 12px;
  }
}
.category {
  border: 1px solid #ddd;
  display: inline-block;
  padding: 2px 8px;
  border-radius: 10px;
  margin: 5px 0;
  font-size: 12px;
}

.description {
  font-size: 14px;
  overflow: hidden;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break: break-word;
  max-width: 100%;
  max-height: 87px;
}

.viewListing {
  display: inline-block;
  margin-bottom: 10px;
  background-color: var(--marketplaceColor);
  padding: 4px 16px;
  color: #fff;
  cursor: pointer;
  border-radius: 4px;
  @media (max-width: 600px) {
   margin-bottom: 0;
  }
}

.messageButton {
  background-color: var(--marketplaceColor);
  padding: 4px 16px;
  color: #fff;
  cursor: pointer;
  border-radius: 4px;
 
}

.imageWrapper {
  padding: 10px;
  position: relative;
  flex: 1;
  
  & .proBadge{
    position: absolute;
    z-index: 10;
    background: #b25f87;
    color: white;
    font-size: 12px;
    padding: 1px 20px;
    display: flex;
    padding-top: 5px;
    gap: 10px;
  }
}

.bottomButtons{
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media(max-width: 600px){
    gap: 10px;
  }
}
.callbackButton{
  display: none;
  @media(max-width: 600px){
    display: block;
  }
}
.headingRow{
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media(max-width: 600px){
    gap: 10px;
  }
}
.verifiedMobile{
  display: none;
  @media(max-width: 600px){
    display: block;
  }
}