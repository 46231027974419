.root {
}

.select {
  color: var(--matterColorAnti);
  border: solid 1px var(--matterColorNegative);
  padding: 0px 15px;
  border-radius: 10px;
  height: 45px;
  padding-right: 40px;

  & > option {
    color: var(--matterColor);
  }

  & > option:disabled {
    color: var(--matterColorAnti);
  }
}

.selectSuccess {
  color: var(--matterColor);
  border-color: var(--successColor);
}

.selectError {
  border-color: var(--failColor);
}
.multiSelectBox {
  /* & :global(#react-select-3-listbox) {
    max-height: 600px !important;
    overflow: hidden;
    position: relative;
    z-index: 1000;
    padding-bottom: 30px;
    & div {
      max-height: 1000px !important;
    }
  } */
  & > span ~ div:nth-child(3) {
    /* box-shadow:unset !important; */
    /* box-shadow: 0 3px 10px rgb(0 0 0 / 0.2); */
    & > div {
      box-shadow: unset !important;
    }
  }
  & > div {
    /* background: #FFFFFF; */
    border-radius: 10px;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    /* height: 48px; */
    color: #525961;
    border-color: rgb(82 89 97 / 80%);
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    &:not(:last-child) {
      min-height: 61px;

      @media (max-width: 767px) {
        min-height: 36px;
      }
    }

    & > div {
      padding: 5px 8px;
      box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
      @media (max-width: 767px) {
        padding: 5px 0px 5px 8px;
        & > div {
          padding-right: 0;
          background-color: transparent;
        }
      }
    }
    & input {
      height: auto;
    }
  }

  & > span + div {
    & > div:first-child {
      & > div:not(:last-child) {
        background: transparent;
        border-radius: 45px;
        width: max-content;
        display: inline-flex;
        align-items: center;
        padding: 0 4px;
        font-size: 15px;
        @media (max-width: 767px) {
          font-family: var(--fontFamily);
          font-size: 16px;
          width: auto;
        }
        & div[role='button'] {
          border: 1.5px solid #98a8b7;
          border-radius: 50%;
          height: 20px;
          width: 20px;
          @media (max-width: 767px) {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.parent {
  display: flex;
  border-bottom: 1px solid;
}

.selectedItem {
  background: #ddd;
}

.inputWrapper {
  display: flex;
  align-items: center;
  gap: 14px;
  height: 18px;
  & input {
    width: 15px;
    height: 15px;
  }
  & label {
    /* font-family: 'Poppins'; */
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #445463;
    white-space: nowrap;
    margin: 0;
    padding: 0;
  }
  & input[type='checkbox'] {
    accent-color: #6558f5;
  }
}
