@import '../../styles/customMediaQueries.css';
@import '../../styles/marketplaceDefaults.css';
/* Can be given to Topbar when a modal is opened
 * so that the Topbar is not rendered on top of it.*/


.vendorContainer {
  margin: 5% 10%;
  padding-top: 50px;

  /* background: #ddd; */
  @media (max-width:767px) {
    margin: 5% 10%;
  }
}

.submitButton {
  display: flex;
  gap: 7px;
  padding: 0;
  align-items: center;
  justify-content: center;
  max-width: 200px;

  @media (max-width: 767px) {

    max-width: 170px;
  }

  & button {
    width: 204px;
    height: 56px;
    font: normal normal bold 20px/32px Roc Grotesk;
    letter-spacing: 0px;
    color: #ffffff;
    border-radius: 0;
    text-align: center;

    @media (max-width: 767px) {
      height: 48px;
      width: 100%;
    }
  }
}

.notInterestedBtn {
  color: #b25f87;
}

.heading {
  border: 2px solid black;
  box-shadow: 0px 0px 8px #ddd;
  padding: 1% 2%;
  background: #000;

  & ul {
    & li {
      color: #fff;
    }
  }
}

.showCards {
  display: flex;
  gap: 20px;

  /* justify-content: center; */
  @media (max-width:1023px) {
    flex-direction: column;
  }
}

.showLeftCard {
  border-right: 2px solid #ddd;
  min-width: 200px;
  background-color: #fff;
}

.showLeftEachCard {
  border-color: #f3f3f6;
  border-style: solid;
  border-width: 0px 0 2px 0;
  cursor: pointer;
  padding: 14px 10px;
  margin: 0;
  border-left: 4px solid #fff;
}

.showLeftEachCardSelected {
  background: #f3f3f6;

  cursor: pointer;
  border-left: 4px solid #2d7af1;
  padding: 10px;
  margin: 0;
}

.middleCard {
  padding: 2px;
  margin-right: 2%;
  justify-content: space-between;
  background-color: rgb(17 22 55 / 1);
}

.showRightCard {
  background-color: #fff;
  padding-left: 60px;

  @media (max-width:1023px) {
    padding-left: 0px;
  }
}

.buttonsGroup {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-top: 24px;
}

.questionsDetails {
  & .cultureQuestion {
    margin-left: 22px;
  }

  & .answerText {
    display: inline-block;

    @media (--viewportMedium) {
      margin: 0 0 18px 0px;
    }
  }

  & .answerTextWithMargin {
    display: inline-block;
    margin: 0 0 16px 22px;

    @media (--viewportMedium) {
      margin: 0 0 25px 20px
    }
  }

  & .listItem {
    margin-left: 20px;
    position: relative;
    font-weight: 600;

    &::before {
      content: '•';
      position: absolute;
      left: -21px;

    }
  }
}

.creditWrapper {
  & .creditHeading {
    font-size: 20px;
    color: #000;
    font-weight: 600;
  }
}

.creditPurchased {
  margin-top: 30px;
  font-size: 16px;
}

.creditButton {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & a {
    color: #ffffff;
    background: #b25f87;
    width: fit-content;
    padding: 12px 35px;
    font-size: 16px;
    border-radius: 5px;
  }
}

.faqText {
  position: relative;

  /* &:last-child{
       & svg{
          top: 34px;
          @media (max-width: 767px) {
            top: 28px;
          }
        }
      } */
  & svg {
    position: absolute;
    top: 23px;
    right: 21px;

    @media (max-width: 767px) {
      top: 28px;
      right: 15px;
    }
  }
}

& :global(.is-open) {
  position: relative;

  &:after {
    position: absolute;
    content: '';
    height: 24px;
    width: 24px;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 12H19' stroke='%233D70A1' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    right: 12px;
  }
}

& :global(.is-closed) {
  position: relative;

  &:after {
    position: absolute;
    content: '';
    height: 24px;
    width: 24px;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 5V19' stroke='%23949494' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M5 12H19' stroke='%23949494' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    right: 12px;
  }
}

& :global(.Collapsible__trigger) {
  /* composes: h1 from global; */
  display: block;
  margin-bottom: 0px;
  padding: 10px 64px 0px 18px;
  color: var(--black-shade-text-black, #272727);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  /* 233.333% */
  height: auto;
  display: flex;
  align-items: center;
  cursor: pointer;

  @media (max-width: 1300px) {
    padding: 15px 48px 0px 18px;
  }

  @media (max-width: 767px) {
    margin-bottom: 12px;
    padding: 15px 44px 0px 12px;
    height: 54px;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
  }
}

& :global(.Collapsible) {
  height: 100%;
}

& :global(.Collapsible__contentInner) {
  margin: 0 15px;
  padding-bottom: 16px;
  color: var(--black-shade-para-text, #949494);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-bottom: 1px solid #dfdfdf;

  word-break: break-word;

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 20px;
  }
}

.credits {
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
}

.respondText {
  color: var(--marketplaceColor);
}

.learWrapper {
  & .listItem {
    margin-bottom: 15px;
  }
}

.answerText {
  display: inline-block;
  margin: 0 0 10px 0px;

  @media (--viewportMedium) {

    margin: 0 0 25px 23px;
  }
}

.questionText {
  display: inline-block;
  margin: 0 0 16px 0;
  font-weight: 600;

}

.questionList {
  list-style: disc;
  padding-left: 20px;
  margin: 0;
}

.backListButton {
  border: none;
  padding: 0;
  margin-bottom: 30px;
  & svg{
    margin-right: 10px;
  }
}
.verifiedText {
  margin-left: 14px;
  background-color: #00f03035;
  padding: 4px 10px;
  border-radius: 50px;
  font-size: 13px;
}
.spentText {
  font-size: 14px;
  color: #ffffff;
  background: #b25f87;
  width: fit-content;
  padding: 4px 12px;
  border-radius: 50px;
  text-align: center;
}